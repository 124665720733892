import React from 'react';
import {
	Toolbar,
	SaveButton,
	DeleteWithConfirmButton,
	ToolbarProps,
	useRecordContext
} from 'react-admin';

interface PrivilegedToolbarProps extends ToolbarProps {
	disableSave?: boolean;
	disableDelete?: boolean;
}

export const PrivilegedToolbar: React.FC<PrivilegedToolbarProps> = ({
	disableSave,
	disableDelete,
	...props
}) => {
	const record = useRecordContext();

	return (
		<Toolbar {...props}>
			{!disableSave && <SaveButton disabled={disableSave} />}
			{!disableDelete && record && (
				<DeleteWithConfirmButton
					disabled={disableDelete}
					confirmTitle="Deleting a record"
					confirmContent="Are you sure you want to delete this record? This action cannot be undone."
				/>
			)}
		</Toolbar>
	);
};
