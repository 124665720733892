import { Schema } from '../../../types';

export const clientAuthSearch = (
	value?: string
): Schema.ClientAuthWhereInput => {
	if (!value) return {};
	value = value.trim();

	if (value === '') return {};

	const valueAsNumber = Number(value);
	const isValueNaN = isNaN(valueAsNumber);

	const filters: Schema.ClientAuthWhereInput['OR'] = [
		{
			client: {
				is: {
					OR: [
						{ practice_name: { contains: value } },
						{ main_contact: { contains: value } }
					]
				}
			}
		}
	];

	if (isValueNaN && !value.match(/[^a-f0-9]/g)) {
		filters.push({ client_identifier: { contains: value } });
	}

	const likelyPhoneNumber = isLikelyPhoneNumber(value);

	if (!isValueNaN && !likelyPhoneNumber) {
		filters.push({ id: { equals: valueAsNumber } });
	}
	if (filters[0]?.client?.is?.OR && likelyPhoneNumber) {
		filters[0].client.is.OR.push({
			sanitized_phone: { startsWith: `1${likelyPhoneNumber}` }
		});
		filters.push({
			sms: {
				is: {
					smsnumber: { startsWith: `1${likelyPhoneNumber}` }
				}
			}
		});
	}

	return { OR: filters };
};

function isLikelyPhoneNumber(input: string) {
	// Remove all non-numeric characters
	let numericString = input.replace(/\D/g, '');
	// Ditch the country code for simplicity down the line
	if (numericString.startsWith('1')) {
		numericString = numericString.substring(1);
	}
	// We'll only gain confidence that the number is likely meant to be a phone number
	// if its digit count is greater than any client_id's, and if it doesn't start with 0 or 1
	if (numericString.length < 5 || ['0', '1'].includes(numericString[0])) {
		return false;
	}
	return numericString;
}
